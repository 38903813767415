import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";
import { Button } from "@material-ui/core";

import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
import Outropiggy from "../components/strapi/structure/Outropiggy";

import OwnersGuide from "../components/strapi/pdf/owners-guide";
import Predelivery from "../components/strapi/pdf/predelivery-guide";
import ModelGuide from "../components/strapi/pdf/model-guide";

export default function Manuals({ data }) {
  return (
    <Layout>
      <SEO title="Manuals" />

      {data.allStrapiManuals.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.manuals_mh.mh_title}
            mhsubtitle={node.manuals_mh.mh_subtitle}
            mhbackground={node.manuals_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.manual_manuals.txtimg_title}
            description={node.manual_manuals.txtimg_description}
            blueTitle={node.manual_manuals.txtimg_bluetitle}
            pdf={<OwnersGuide />}
            bgColor={node.manual_manuals.txtimg_bgcolor}
            order={node.manual_manuals.txtimg_order}
            rowimg={node.manual_manuals.txtimg_img.publicURL}
          />
          <Craftsmanship
            title={node.manuals_craftsmanship.craft_title}
            description={node.manuals_craftsmanship.craft_description}
          />
          <Twocol
            title={node.manuals_predelivery.txtimg_title}
            description={node.manuals_predelivery.txtimg_description}
            pdf={<Predelivery />}
            bgColor={node.manuals_predelivery.txtimg_bgcolor}
            order={node.manuals_predelivery.txtimg_order}
            rowimg={node.manuals_predelivery.txtimg_img.publicURL}
          />
          <Twocol
            title={node.manuals_range.txtimg_title}
            description={node.manuals_range.txtimg_description}
            blueTitle={node.manuals_range.txtimg_bluetitle}
            pdf={<ModelGuide />}
            bgColor={node.manuals_range.txtimg_bgcolor}
            order={node.manuals_range.txtimg_order}
            rowimg={node.manuals_range.txtimg_img.publicURL}
          />
          <Outropiggy outro={node.manuals_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Manuals {
    allStrapiManuals {
      edges {
        node {
          id # id of the node
          manuals_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          manual_manuals {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_img {
              publicURL
            }
          }
          manuals_craftsmanship {
            id
            craft_title
            craft_description
          }
          manuals_predelivery {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_img {
              publicURL
            }
          }
          manuals_range {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_img {
              publicURL
            }
          }
          manuals_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
